
.leaflet-popup-content-wrapper {
  background-color: #00B7FF;
}

header {
  background-color: #282c34;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font: bold 4.5rem arial, sans-serif;
}

@media only screen and (max-width: 600px) {
  header {
    font: bold 2rem arial, sans-serif;
  }

  .header_headline {
    flex-direction: column !important;
    margin-left: 110px;
    align-items: flex-start !important;
  }

  .ausflug_text {
    margin-left: 0 !important;
  }

  header > div > span {
    margin-left: 0 !important;
  }
}

hr {
  position: relative;
  z-index: 100;
  margin: 0;
  background-color: #fbaa33;
  height: 5px;
  border: none;
  box-shadow: 1px 1px 3px #fbaa33;
}
.map_container {
  height: 90vh;
}


.logo-wrap {
  filter: drop-shadow(1px 1px 1.5px rgb(251, 246, 255));
}

svg {
  transform: scale(0.3);
  z-index: 100;
  position: absolute;
  left: -70px;
  top: -100px;
}

.ausflug_text {
  margin-left: 130px;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #fbaa33;
}

header > div > span {
  color: white;
  margin-left: 14px;
}

.header_headline {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: row;
}
