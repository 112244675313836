/**
  Hamburger
**/

.burger {
    height: 50px;
}

.hamburger {
    cursor: pointer;
    margin-right: 80px;
}

.hamburger div {
    width: 40px;
    height: 7px;
    border-radius: 3px;
    background-color: #fbaa33;
    margin-top: 8px;
    transition: all 0.3s ease-in-out;
}

#toggle {
    display: none;
}


/**
Animations
**/
#toggle:checked + .hamburger .top-bun {
    transform: rotate(-45deg);
    margin-top: 25px;
}

#toggle:checked + .hamburger .bottom-bun {
    opacity: 0;
    transform: rotate(45deg);
}

#toggle:checked + .hamburger .meat {
    transform: rotate(45deg);
    margin-top: -7px;
}
